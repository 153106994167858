<template lang="pug">
head-meta(title-page="Избранные товары" )
user-menu(block-name="Избранное")
tile-line(v-if="products.length", :products="products", :productsInBasket="productsInBasket")
the-favorites(v-else)
contacts-line
</template>

<script>
import UserMenu from '../components/_user/UserMenu'
import TileLine from '../components/_tiles/TileLine'
import { getFavorites, getBasket } from '../assets/scripts/API'
import TheFavorites from '../components/favorites/TheFavorites'
import HeadMeta from '../components/_layout/HeadMeta'
import ContactsLine from '../components/_layout/TheContactsLine'

export default {
  name: 'FavoritePage',
  components: { ContactsLine, HeadMeta, TheFavorites, TileLine, UserMenu },
  data () {
    return {
      products: [],
      productsInBasket: []
    }
  },
  async mounted () {
    const productsObject = await getFavorites()
    this.products = productsObject.products
    const basket = await getBasket()
    this.productsInBasket = basket.products
  }
}
</script>
