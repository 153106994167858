<template lang="pug">
.favorites
  .favorites__cont.cont
    .favorites__text.text-big Избранных товаров пока нет. Жмите ♡ на странице товара и добавляйте сюда то, что нравится
    .favorites__button-box
      router-link.favorites__login.button-white(:to="{ name: 'Login' }", v-if="!user || !user.is_auth ") Войти в кабинет
      router-link.favorites__home-link.link-more(:to="{ name: 'Home' }") На&nbsp;главную
</template>

<script>
import '@/assets/styles/components/favorites.sass'
import { getUser } from '../../assets/scripts/API'
export default {
  name: 'TheFavorites',
  data () {
    return {
      user: null
    }
  },
  async mounted () {
    this.user = await getUser()
  }
}
</script>
